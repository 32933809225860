import {
  GetPurchasesApiResponseData,
  ApiDocument,
  ApiPurchase,
  GetPurchasesResponseData
} from 'Services/apiResponseTypes'
import { Purchase, ReportedPurchase } from 'Utils/types/purchase'
import { PurchaseDocument } from 'Utils/types/purchaseDocument'
import { parseServerDate } from 'Utils/helpers/dateUtils'
import { ApiReportedPurchase } from 'Services/JOONAdmin/adminApiResponseTypes'

export const parseDashboardResponseData = (
  data: GetPurchasesApiResponseData
): GetPurchasesResponseData => {
  const { purchases } = data

  return {
    purchases: purchases.map(parsePurchase)
  }
}

export const parsePurchase = (purchase: ApiPurchase): Purchase => {
  const {
    id,
    amount,
    transaction_date,
    name,
    plaid_category,
    reported,
    parent_id,
    benefit_program_name,
    benefit_program_id,
    benefit_program_transactions,
    opr_status,
    opr_request_eligible,
    report_details,
    iso_currency_code,
    documents
  } = purchase

  // A purchase having a BPT with "approved_for_reimbursement" is what determines if it is approved
  const approved = benefit_program_transactions.some((t) => t.approved_for_reimbursement)

  return {
    id,
    amount,
    name,
    pending: reported && report_details?.status === 'REPORTED',
    approved,
    category: plaid_category,
    approvedProgramId: benefit_program_id,
    parentId: parent_id ?? undefined,
    approvedProgramName: benefit_program_name ?? report_details?.reported_program_name,
    reportedProgramName: report_details?.reported_program_name,
    date: parseServerDate(transaction_date),
    oprStatus: opr_status,
    source: reported ? 'Manually added' : 'Purchase account',
    dateReported: report_details ? parseServerDate(report_details.reported_at) : undefined,
    currencyCode: iso_currency_code,
    oprRequestEligible: opr_request_eligible ?? false,
    documents:
      documents?.documents_list?.map((document) => {
        return parsePurchaseDocument(document)
      }) ?? []
  }
}

export const parsePurchaseDocument = (document: ApiDocument): PurchaseDocument => {
  const { id, original_filename, file_type, filename, urls } = document

  return {
    id,
    filename,
    urls,
    fileType: file_type,
    originalFilename: original_filename
  }
}

export const parseReportedPurchase = (reportedPurchase: ApiReportedPurchase): ReportedPurchase => {
  const {
    id,
    status,
    createdAt,
    updatedAt,
    description,
    benefitProgramId,
    benefitProgramName,
    benefitProgramCategoryGuide,
    user,
    txn,
    benefitProgramCategory,
    rejectionReason
  } = reportedPurchase

  const {
    id: purchaseId,
    transaction_date,
    iso_currency_code,
    name,
    opr_status,
    amount_precise,
    approved_for_reimbursement,
    plaid_category,
    serialized_documents
  } = txn

  const { id: userId, email, employer } = user

  return {
    id,
    purchaseId,
    userId,
    name,
    description,
    status,
    userEmail: email,
    programName: benefitProgramName,
    categoryGuide: benefitProgramCategoryGuide || '',
    amount: amount_precise,
    oprStatus: opr_status,
    currencyCode: iso_currency_code,
    approvedForReimbursement: approved_for_reimbursement,
    employerName: employer?.name,
    reportedCategory: benefitProgramCategory?.basic_benefit_program_category?.name,
    plaidCategory: plaid_category?.name || '',
    benefitProgramId: parseInt(benefitProgramId),
    dateReported: parseServerDate(createdAt),
    dateUpdated: parseServerDate(updatedAt),
    date: parseServerDate(transaction_date),
    rejectionReason: rejectionReason,
    documents:
      serialized_documents?.documents_list?.map((document) => {
        return parsePurchaseDocument(document)
      }) ?? []
  }
}
