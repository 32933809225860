import { createContext, useContext, useReducer } from 'react'
import { ContextProviderProps } from 'Contexts/ContextProvider'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { ReportedPurchase } from 'Utils/types/purchase'
import { propEq, reject } from 'ramda'
import { REACT_APP_JOON_EMPLOYER_ID } from 'Services/config'

export enum JoonAdminActionTypes {
  SetEmployers = 'SET_EMPLOYERS',
  SetSelectedEmployer = 'SET_SELECTED_EMPLOYER',
  SetReportedPurchases = 'SET_REPORTED_PURCHASES',
  SetGhostModeActive = 'SET_GHOST_MODE_ACTIVE',
  SetJoonAdminUserId = 'SET_JOON_ADMIN_USER_ID',
  UpdateReportedPurchaseStatus = 'UPDATE_REPORTED_PURCHASE_STATUS',
  SetJoonAdminEmployerId = 'SET_JOON_ADMIN_EMPLOYER_ID',
  ResetState = 'RESET_STATE',
  AddInvoiceModal = 'ADD_INVOICE_MODAL',
  CreateEmployerModal = 'CREATE_EMPLOYER_MODAL'
}
type Action =
  | { type: JoonAdminActionTypes.SetEmployers; value: Employer[] }
  | { type: JoonAdminActionTypes.SetSelectedEmployer; value: Employer | undefined }
  | { type: JoonAdminActionTypes.SetReportedPurchases; value: ReportedPurchase[] }
  | { type: JoonAdminActionTypes.UpdateReportedPurchaseStatus; value: ReportedPurchase['id'] }
  | { type: JoonAdminActionTypes.SetGhostModeActive; value: boolean }
  | { type: JoonAdminActionTypes.SetJoonAdminUserId; value: number | undefined }
  | { type: JoonAdminActionTypes.SetJoonAdminEmployerId }
  | { type: JoonAdminActionTypes.ResetState }
  | { type: JoonAdminActionTypes.AddInvoiceModal; value: boolean }
  | { type: JoonAdminActionTypes.CreateEmployerModal; value: boolean }

type Dispatch = (action: Action) => void
type State = {
  employers: Employer[]
  reportedPurchases: ReportedPurchase[]
  ghostModeActive: boolean
  selectedEmployer?: Employer
  joonAdminUserId?: number
  joonAdminEmployerId?: number
  addInvoiceModal: boolean
  createEmployerModal: boolean
}

const DEFAULT_STATE: State = {
  employers: [],
  reportedPurchases: [],
  ghostModeActive: false,
  addInvoiceModal: false,
  createEmployerModal: false
}

const JoonAdminStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

const joonAdminReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case JoonAdminActionTypes.SetEmployers: {
      return { ...state, employers: action.value }
    }
    case JoonAdminActionTypes.SetSelectedEmployer: {
      return { ...state, selectedEmployer: action.value }
    }
    case JoonAdminActionTypes.SetReportedPurchases: {
      return { ...state, reportedPurchases: action.value }
    }
    case JoonAdminActionTypes.UpdateReportedPurchaseStatus: {
      const purchases = reject(propEq(action.value, 'purchaseId'), state.reportedPurchases)

      return { ...state, reportedPurchases: purchases }
    }
    case JoonAdminActionTypes.SetGhostModeActive: {
      return { ...state, ghostModeActive: action.value }
    }
    case JoonAdminActionTypes.SetJoonAdminUserId: {
      return { ...state, joonAdminUserId: action.value }
    }
    case JoonAdminActionTypes.SetJoonAdminEmployerId: {
      return { ...state, joonAdminEmployerId: REACT_APP_JOON_EMPLOYER_ID }
    }
    case JoonAdminActionTypes.AddInvoiceModal: {
      return { ...state, addInvoiceModal: action.value }
    }
    case JoonAdminActionTypes.CreateEmployerModal: {
      return { ...state, createEmployerModal: action.value }
    }
    case JoonAdminActionTypes.ResetState: {
      return DEFAULT_STATE
    }
    default: {
      throw new Error(`Invalid action type`)
    }
  }
}

const JoonAdminProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(joonAdminReducer, DEFAULT_STATE)
  const value = { state, dispatch }
  return <JoonAdminStateContext.Provider value={value}>{children}</JoonAdminStateContext.Provider>
}

const useJoonAdminContext = () => {
  const context = useContext(JoonAdminStateContext)
  if (context === undefined) {
    throw new Error('useJoonAdminContext must be used within an JoonAdminProvider')
  }
  return context
}

export { JoonAdminProvider, useJoonAdminContext }
